import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { Checkbox, ManageManyElements } from 'components';

import shared from 'src/styles/Shared.module.scss';

const useManageMany = (props) => {
  const {
    openButtonLabel = 'Zarządzaj wieloma',
    executeButtonLabel = 'Zastosuj dla',
    options = [],
    allElements = [],
    buttonProps
  } = props;

  const [areManagementEnabled, setAreManagementEnabled] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const location = useLocation();

  const resetHandler = () => {
    setAreManagementEnabled(false);
  };

  useEffect(() => {
    setSelectedElements([]);
  }, [location.search]);

  const renderSingleCheckbox = (elementId, className) => {
    const isElementSelected = selectedElements.includes(elementId);

    if (!areManagementEnabled) return null;

    const onChange = () => {
      if (isElementSelected) setSelectedElements((prev) => prev.filter((el) => el !== elementId));
      else setSelectedElements((prev) => [...prev, elementId]);
    };

    return (
      <Checkbox
        onChange={onChange}
        value={isElementSelected}
        className={cn(shared.checkboxWithBg, className)}
      />
    );
  };

  const renderCheckboxForGroup = (elementIds, className) => {
    const areAllElementsSelected = !elementIds.some((el) => !selectedElements.includes(el));

    if (!areManagementEnabled) return null;

    const onChange = () => {
      if (areAllElementsSelected) setSelectedElements((prev) => prev.filter((el) => !elementIds.includes(el)));
      else setSelectedElements((prev) => [...prev, ...elementIds]);
    };

    return (
      <Checkbox
        onChange={onChange}
        value={areAllElementsSelected}
        className={cn(shared.checkboxWithBg, className)}
      />
    );
  };

  const renderSelectAllCheckbox = (checkboxConfig = {}, className) => {
    const isElementSelected = allElements.length === selectedElements.length;

    if (!areManagementEnabled) return null;

    const onChange = () => {
      if (isElementSelected) setSelectedElements([]);
      else setSelectedElements(allElements.map((el) => el.id));
    };

    return (
      <Checkbox
        onChange={onChange}
        value={isElementSelected}
        className={cn(shared.checkboxWithBg, className)}
        {...checkboxConfig}
      />
    );
  };

  const BulkManagementModule = (
    <ManageManyElements
      setAreManagementEnabled={setAreManagementEnabled}
      areManagementEnabled={areManagementEnabled}
      executeButtonLabel={executeButtonLabel}
      selectedElements={selectedElements}
      openButtonLabel={openButtonLabel}
      resetHandler={resetHandler}
      options={options}
      buttonProps={buttonProps}
    />
  );

  return {
    BulkManagementModule,
    selectedElements,
    selectAllCheckbox: renderSelectAllCheckbox(),
    renderSelectAllCheckbox,
    renderSingleCheckbox,
    renderCheckboxForGroup,
    resetHandler
  };
};

export default useManageMany;
