import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, useDepartments } from 'components';

import { getDepartments } from 'src/features/Departments/actions';
import { compareArrays } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const departmentToOption = (department) => {
  const { id, name } = department;
  return { label: name, value: Number(id) };
};

const FilterByDepartment = ({ params, isMulti, isSearchable = false }) => {
  const [selectedDepartment, setSelectedDepartment] = useState(isMulti ? [] : null);
  const dispatch = useDispatch();
  const myDepartments = useDepartments();

  const paramValue = params.get('department');

  const departmentOptions = useMemo(() => {
    return myDepartments.departments.map((item) => departmentToOption(item)) || [];
  }, [myDepartments.departments]);

  const setSingleValue = (department) => {
    const areValueTheSame = department?.value === +paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'department', value: department?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('department', department?.value);
      }
    }
  };

  const setMultiValue = (departments) => {
    const idsArray = departments?.map((sup) => sup.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'department', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('department', idsArray);
      }
    }
  };

  useEffect(() => {
    if (paramValue) {
      if (isMulti) {
        const selected = departmentOptions.filter((option) => paramValue.includes(String(option.value)));
        setSelectedDepartment(selected);
      } else {
        const selected = departmentOptions.find((option) => option.value === +paramValue);
        setSelectedDepartment(selected);
      }
    }
  }, [departmentOptions]);

  const onChangeHandler = (e) => {
    setSelectedDepartment(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  return (
    <Select
      value={selectedDepartment}
      onChange={onChangeHandler}
      placeholder={'Jednostka organizacyjna'}
      options={departmentOptions}
      wrapperStyle={style.departmentSelect}
      isClearable
      isSearchable={isSearchable}
      isMulti={isMulti}
    />
  );
};

export default FilterByDepartment;
