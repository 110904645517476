import React from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';

import { FilterByCategory, FilterByProducer, FilterBySupplier, Searchbar, SwitchButton, useIsAdmin } from 'components';
import SortByOptions from 'components/layout/sorting/SortByOptions';

import style from './Filtering.module.scss';

const sortOptions = [
  { label: 'TOP 20 produktów pod względem wartości zamówień', value: 2, sort_by: 'VALUE', sort_order: 'DESC', top: 20 },
  { label: 'TOP 20 produktów pod względem zamówionych sztuk', value: 4, sort_by: 'QUANTITY', sort_order: 'DESC', top: 20 },
  { label: 'Produkty z ceną ref.', value: 5, type: 'only_with_ref_price' },
  { label: 'Produkty z wolumenem', value: 6, type: 'only_with_volume' },
  { label: 'Produkty z ceną ref. i wolumenem', value: 7, type: 'with_ref_and_volume' },
  { label: 'Produkty bez ceny ref. i wolumenu', value: 8, type: 'without_ref_and_volume' }
];

const Filtering = ({ params, selectedCompany, onlyWithoutOffers, setOnlyWithoutOffers }) => {
  const { listUID } = useParams();
  const isAdmin = useIsAdmin();

  const isOptionDisabled = (supplier) => supplier?.is_hidden;

  return (
    <>
      <div className={cn(style.container, { [style.fromList]: listUID })}>
        <Searchbar
          params={params}
          fullWidth
          filters={
            <>
              <SortByOptions
                params={params}
                placeholder={'TOP produkty'}
                options={sortOptions}
                wrapperStyle={style.sortSelect}
              />
              <span className={style.divider} />
              <FilterByCategory
                params={params}
                queryParams={listUID ? { filter_by: 'LIST', list_uid: listUID } : undefined}
                fullWidth
                isMulti
              />
              <FilterBySupplier
                params={params}
                queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
                isOptionDisabled={isOptionDisabled}
                disabledElements
                asUser
                fullWidth
                isMulti
              />
              <FilterByProducer
                params={params}
                fullWidth
              />
            </>
          }
        />
      </div>
      <SwitchButton
        value={onlyWithoutOffers}
        setValue={setOnlyWithoutOffers}
        label={'Pokaż produkty bez ofert'}
      />
    </>
  );
};

export default Filtering;
