import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Modal, SaveButton, useIsAdmin, useIsMobile, useModalConfirm } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { tabletHorizontalWidth } from 'src/constants/responsive';

import { refreshCartOrCatalog } from '../../actions';
import DropzoneArea from './components/DropzoneArea';
import List from './components/List';
import ProductDetails from './components/ProductDetails';
import SupplierThumbnail from './components/SupplierThumbnail';

import style from './SuppliersList.module.scss';

const SuppliersList = ({ product, isQuietLoading, selectedCompany }) => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);
  const [isModalDeleteOfferOpen, setIsModalDeleteOfferOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const [isDragged, setIsDragged] = useState(false);

  const deletePrice = async (id) => {
    const data = {
      id,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await OfferCatalogApi.deleteSinglePrice(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteProduct = async () => {
    const data = {
      product_id: product.product_id,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await OfferCatalogApi.deleteProduct(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteProductHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć wszystkie oferty na ten produkt? Nie będzie on widoczny po usunięciu.',
      handleConfirm: deleteProduct,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  if (!product) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames(style.container, { [style.isQuietLoading]: isQuietLoading })}>
        <div className={style.productRow}>
          <ProductDetails product={product} />

          {isTabletHorizontal && (
            <div className={style.buttons}>
              <Button
                label='Dodaj do listy'
                iconName={'add'}
                onClick={() => setIsModalAddToListOpen(true)}
              />
              <Button
                label='Usuń produkt z oferty'
                iconName={'trash'}
                gray
              />
            </div>
          )}
        </div>

        <p className={style.description}>
          Aby ustawić ofertę jako <strong>preferowaną</strong>, <strong>alternatywną</strong> lub <strong>zapasową</strong>,
          przeciągnij element z listy na odpowiednie pole. Przypisane oferty będą brane pod uwagę podczas składania zamówienia.
        </p>

        <DropzoneArea
          product={product}
          isDragged={isDragged}
          setIsDragged={setIsDragged}
        />
        <List
          product={product}
          setIsDragged={setIsDragged}
        />
      </div>

      <Modal
        visible={isModalDeleteOfferOpen}
        onClose={() => setIsModalDeleteOfferOpen(false)}
        title={'Usuń wybraną ofertę'}
      >
        <div className={style.wrapper}>
          {product.prices.map((price) => (
            <SupplierThumbnail
              key={price.id}
              item={price}
              notDraggable
              deletePrice={deletePrice}
            >
              <SaveButton
                type={'delete'}
                onClick={() => deletePrice(price.id)}
              />
            </SupplierThumbnail>
          ))}
        </div>
      </Modal>
      <AddProductToList
        title={`Dodaj "${product.name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={product.product_id}
      />
    </DndProvider>
  );
};

export default SuppliersList;
