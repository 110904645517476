import ScanbotSDK from 'scanbot-web-sdk/ui';

export const startScanner = (config) => {
  const result = ScanbotSDK.UI.createBarcodeScanner(config);
  return result;
};

export const applySingleScanningUseCase = (config) => {
  const useCase = new ScanbotSDK.UI.Config.SingleScanningMode();

  useCase.confirmationSheetEnabled = true;
  useCase.sheetColor = '#FFFFFF';

  // Hide/unhide the barcode image.
  useCase.barcodeImageVisible = true;

  // Configure the barcode title of the confirmation sheet.
  useCase.barcodeTitle.visible = true;
  useCase.barcodeTitle.color = '#0a1b2d';

  // Configure the barcode subtitle of the confirmation sheet.
  useCase.barcodeSubtitle.visible = true;
  useCase.barcodeSubtitle.color = '#0a1b2d';

  // Configure the cancel button of the confirmation sheet.
  useCase.cancelButton.text = 'Anuluj';
  useCase.cancelButton.foreground.color = '#0a1b2d';
  useCase.cancelButton.background.fillColor = '#e0e1d5';

  // Configure the submit button of the confirmation sheet.
  useCase.submitButton.text = 'Potwierdź';
  useCase.submitButton.foreground.color = '#FFFFFF';
  useCase.submitButton.background.fillColor = '#1d7268';
  useCase.submitButton.background.strokeColor = '#1d7268';

  config.useCase = useCase;
};

export const applyTopBarConfig = (config) => {
  config.topBar.backgroundColor = '#1d7268';
  config.topBar.title.text = 'Zeskanuj kod EAN';
  config.topBar.cancelButton.text = 'Powrót';
  config.topBar.cancelButton.foreground.color = '#FFFFFF';
};

export const applyActionBarConfig = (config) => {
  config.actionBar.flashButton.visible = true;
  config.actionBar.zoomButton.visible = true;
  config.actionBar.flipCameraButton.visible = true;
};

export const applyPermissionScreenConfig = (config) => {
  config.cameraPermission.enableCameraTitle.text = 'Brak uprawnień';
  config.cameraPermission.enableCameraExplanation.text =
    'Aby móc skanować kody EAN, musisz udzielić uprawnień do użycia aparatu.';
  config.cameraPermission.closeButton.text = 'Zamknij';
};
