import React, { useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';

import { Icon, Tag, useSearchParam } from 'components';

import { ProductsApi, UserApi } from 'src/api';
import { notifyApiError } from 'src/components/layout/Toasts';

import FilterValueItem from './components/FilterValueItem';

import style from './ActiveFilters.module.scss';

const ActiveFilters = ({ params }) => {
  const selectedProducer = useSearchParam(params, 'producer');
  const selectedSuppliers = useSearchParam(params, 'supplier');
  const selectedCategories = useSearchParam(params, 'category');

  const [activeFilters, setActiveFilters] = useState({
    producer: [],
    supplier: [],
    category: []
  });

  const loadFilterData = async (apiCallback, query) => {
    try {
      const { data } = await apiCallback({
        perPage: 1,
        page: 1,
        ...query
      });
      return data;
    } catch (err) {
      notifyApiError(err);
    }
  };

  const removeFilter = (key, value) => {
    const current = params.get(key);
    const values = isArray(current) ? current : [current];
    const newValues = values.filter((item) => item !== value);

    if (newValues.length) {
      params.set(key, newValues);
    } else {
      params.remove(key);
    }
  };

  const isFilterChanged = (key, val) => {
    return activeFilters[key].find((item) => item.value === val) === undefined;
  };

  const transformFilterData = async (key, val) => {
    if (!val) {
      setActiveFilters((prev) => ({ ...prev, [key]: [] }));
      return;
    }

    let items = Array.isArray(val) ? val : [val];
    let loadedItems = [];
    let newItems = [];

    items = items.filter((item) => {
      if (!isFilterChanged(key, item)) {
        loadedItems.push(activeFilters[key].find((activeItem) => activeItem.value === item));
        return false;
      }
      return true;
    });

    // Load text for each remaining filter value by its value (id)
    newItems = await Promise.all(
      items.map(async (item) => {
        let text;
        let color;

        if (key === 'producer') {
          const data = await loadFilterData(ProductsApi.getProducers, { producer_id: item });
          text = data?.data?.[0]?.producer_name;
        } else if (key === 'supplier') {
          const data = await loadFilterData(UserApi.getDistributors, { supplier_id: item });
          text = data?.[0]?.name;
          color = data?.[0]?.color;
        } else if (key === 'category') {
          const data = await loadFilterData(ProductsApi.getCategories, { category_id: item });
          text = data?.[0]?.label;
        }

        return { key, value: item, text: text || '-', color: color };
      })
    );

    setActiveFilters((prev) => ({
      ...prev,
      [key]: [...loadedItems, ...newItems]
    }));
  };

  const getActiveFilters = async () => {
    transformFilterData('supplier', selectedSuppliers);
    transformFilterData('category', selectedCategories);
    transformFilterData('producer', selectedProducer);
  };

  useEffect(() => {
    getActiveFilters();
  }, [selectedProducer, selectedSuppliers, selectedCategories]);

  return (
    <div className={style.container}>
      {activeFilters.category.map(({ key, value, text }) => (
        <FilterValueItem
          key={`${key} - ${value}`}
          value={text}
          onDelete={() => removeFilter(key, value)}
        />
      ))}

      {activeFilters.producer.map(({ key, value, text }) => (
        <FilterValueItem
          key={`${key} - ${value}`}
          value={text}
          onDelete={() => removeFilter(key, value)}
        />
      ))}

      {activeFilters.supplier.map(({ key, value, text, color }) => (
        <FilterValueItem
          key={`${key} - ${value}`}
          value={text}
          color={color}
          onDelete={() => removeFilter(key, value)}
        />
      ))}
    </div>
  );
};

export default ActiveFilters;
