import React, { useEffect, useState } from 'react';

import { SearchWithFilters } from 'components';
import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

const Searchbar = (props) => {
  const { params, alwaysExpand, searchSize, className, fullWidth, disabled, placeholder, paramName = 'search', filters } = props;
  const [search, setSearch] = useState();

  const wrapperProps = getWrapperProps(props);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (search !== params.get(paramName) && params.get('page')) {
        params.setFew([
          { key: paramName, value: search },
          { key: 'page', value: '1' }
        ]);
      } else if (search !== params.get(paramName)) {
        params.set(paramName, search);
      }
    }, 400);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [search]);

  useEffect(() => {
    if (params.get(paramName)) {
      setSearch(params.get(paramName));
    }
  }, []);

  return (
    <InputWrapper {...wrapperProps}>
      <SearchWithFilters
        state={search}
        setState={setSearch}
        alwaysExpand={alwaysExpand}
        searchSize={searchSize}
        className={className}
        fullWidth={fullWidth}
        disabled={disabled}
        placeholder={placeholder}
        filters={filters}
      />
    </InputWrapper>
  );
};

export default Searchbar;
