import React, { useEffect } from 'react';
import ScanbotSDK from 'scanbot-web-sdk/ui';
// For some reason it doesn't work with evn variable
const LICENSE_KEY =
  'RHr9dzyYYlvl5MCfRieiZ9GO7NQcLT' +
  'cRflTu3aMv0GNMk7ROsb7Cs4n8FtmZ' +
  'gQ7g9dfZxS3v5XRuyrpwuv4qmuJeAx' +
  'u3MkdXTYYCCzKqVFqZ2LHKbyEFV8fc' +
  '1DWQIEuUB/LRvR09I/R0yII8vlgVol' +
  '3tL1AuWTMym28FQgCh1seNFMB7v3Gp' +
  'hToiaz3l2Pjk/QJWDNTTrixhUNkgNL' +
  'iQcHrStxH7Q+fi6TA3KdY1ulPla4pQ' +
  'LmtI50grPbv6Mn4EB/rMxvYJQ0t0mO' +
  'VFLDlEiLB7N13ZBm20J7RGnNLVj/zW' +
  'uMVTfNu3zIRXyZiT03LBjv3AFyeTXv' +
  'DJ5hZbLMu+Xg==\nU2NhbmJvdFNESw' +
  'psb2NhbGhvc3R8YXBwLmF2YWxpby5p' +
  'bwoxNzM0NDc5OTk5CjUxMgo4\n';

const InitializeScanner = () => {
  useEffect(() => {
    const environment = import.meta.env.VITE_NODE_ENV;
    const init = async () => {
      // if (environment === 'production') {
      //   await ScanbotSDK.initialize({ licenseKey: LICENSE_KEY_PROD, engine: '/scanner/' });
      // } else if (environment === 'staging') {
      //   await ScanbotSDK.initialize({ licenseKey: LICENSE_KEY_STAGING, engine: '/scanner/' });
      // } else if (environment === 'demo') {
      //   await ScanbotSDK.initialize({ licenseKey: LICENSE_KEY_DEMO, engine: '/scanner/' });
      // } else {
      //   await ScanbotSDK.initialize({ licenseKey: '', engine: '/scanner/' });
      // }
      await ScanbotSDK.initialize({ licenseKey: LICENSE_KEY, engine: '/scanner/' });
    };

    init();
  }, []);
  return null;
};
export default InitializeScanner;
