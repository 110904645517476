import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CategoriesList, Modal, MoveElementForm, Tag, Thumbnail, useIsMobile, useModalConfirm, usePermissions } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { tabletHorizontalWidth } from 'src/constants/responsive';
import { refreshList } from 'src/features/Lists/actions';
import { handleNumeralWords } from 'src/utils/helpers';

import EditListName from '../EditListName';

import shared from 'styles/Shared.module.scss';

const ListThumbnail = ({ item: list }) => {
  const [isCompanyAdmin] = usePermissions([userPermissions.company.admin]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const isMobile = useIsMobile();
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [canDeleteList] = usePermissions([userPermissions.lists.delete]);
  const [canEditList] = usePermissions([userPermissions.lists.create]);

  const handleDelete = async () => {
    try {
      await ListsApi.deleteList(list.uid);
      notifyCommon([`Lista ${list.name} została usunięta.`]);
      dispatch(refreshList());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleNameChange = async () => {
    setVisible(true);
  };

  const handleListDuplicate = async () => {
    const data = {
      title: `Kopia z ${list.name}`,
      uid: list.uid
    };

    try {
      await ListsApi.ListDuplicate(data);
      notifyCommon([`Lista ${list.name} została skopiowana.`]);
      dispatch(refreshList());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const menu = [
    {
      title: 'Zmień nazwę',
      icon: 'design',
      action: handleNameChange,
      disabled: !canEditList
    },
    {
      title: 'Duplikuj',
      icon: 'copy',
      action: handleListDuplicate,
      disabled: !canEditList
    },
    {
      title: 'Przenieś listę',
      icon: 'move',
      color: 'blue',
      roles: isCompanyAdmin ? ['admin', 'user'] : ['admin'],
      action: () => setIsModalOpen(true),
      disabled: !canEditList
    },
    {
      title: 'Pokaż na rynku',
      icon: 'search',
      color: 'blue',
      roles: ['admin'],
      action: () => history.push(`/market-prices/${list.uid}`)
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: () =>
        handleOpenModalConfirm({
          message: `Czy na pewno chcesz usunąć listę ${list.name} ?`,
          handleConfirm: handleDelete
        }),
      disabled: !canDeleteList
    }
  ];

  return (
    <Thumbnail
      item={list}
      menu={menu}
      small={isMobile}
      link={`/lists/${list.uid}`}
      status={
        list.products_count && (
          <Tag
            value={`${list.products_count} ${handleNumeralWords(['produkt', 'produkty', 'produktów'], list.products_count)}`}
            title={`${list.products_count} ${handleNumeralWords(['produkt', 'produkty', 'produktów'], list.products_count)}`}
          />
        )
      }
      modals={
        <>
          <Modal
            visible={visible}
            onClose={() => setVisible(false)}
            title={'Zmień nazwę listy'}
          >
            <EditListName
              uid={list.uid}
              name={list.name}
              closeModal={() => setVisible(false)}
            />
          </Modal>
          <Modal
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title={'Przenieś listę: ' + list.name}
          >
            <MoveElementForm
              uid={list.uid}
              closeModal={() => setIsModalOpen(false)}
              refreshCallback={() => dispatch(refreshList())}
              apiCallback={ListsApi.moveList}
              buttonLabel={'Przenieś listę'}
              isCompanyAdmin={isCompanyAdmin}
            />
          </Modal>
          {renderedModalConfirm}
        </>
      }
    >
      {!isTabletHorizontal && (
        <div className={shared.thumbnailContentWrapper}>
          <CategoriesList
            uid={list.uid}
            categories={list.tags}
          />
        </div>
      )}
    </Thumbnail>
  );
};

export default ListThumbnail;
