import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Icon, Loader, Modal } from 'components';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { ReactComponent as MicrophoneIcon } from 'src/assets/icons/microphone.svg';

import style from './SearchWithFilters.module.scss';

const SearchWithFilters = (props) => {
  const {
    className,
    inputClasses,
    children,
    state = '',
    setState,
    searchSize = 'small',
    isLoading,
    alwaysExpand,
    fullWidth,
    onClear,
    clearButtonVisible,
    disabled,
    placeholder,
    speechToText = false,
    filters,
    ...rest
  } = props;

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);

  const openFiltersModal = () => setFiltersVisible(true);
  const closeFiltersModal = () => setFiltersVisible(false);

  const onInputChange = (e) => {
    if (setState) {
      setState(e.target.value);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    if (setState) {
      setState('');
    }
  };

  useEffect(() => {
    setIsClearButtonVisible(state !== '');
  }, [state]);

  const visibleClassNames = cn({
    [style[searchSize]]: searchSize,
    [style.expand]: alwaysExpand || state !== '',
    [style.fullWidth]: fullWidth,
    [style.disabled]: disabled
  });

  return (
    <>
      <div className={cn(style.search, visibleClassNames, className)}>
        <div
          className={cn(style.head, {
            [style.fullWidth]: fullWidth
          })}
        >
          {filters ? (
            <button
              className={style.filtersButton}
              onClick={openFiltersModal}
            >
              <Icon
                name='filter'
                size='24'
              />
            </button>
          ) : (
            <Icon
              className={style.searchIcon}
              name='search'
              size='24'
            />
          )}

          <input
            data-hj-allow
            className={cn(style.input, visibleClassNames, inputClasses)}
            value={state}
            onChange={onInputChange}
            type='text'
            placeholder={placeholder || 'Wyszukaj...'}
            disabled={disabled}
            {...rest}
          />
          {(isClearButtonVisible || clearButtonVisible) && (
            <button
              className={style.clear}
              title={'Wyczyść'}
              onClick={handleClear}
            >
              <CloseIcon />
            </button>
          )}
          {speechToText && (
            <button
              className={style.speechToText}
              title={'Wyszukiwanie mową'}
            >
              <MicrophoneIcon />
            </button>
          )}
        </div>
        <div className={style.body}>
          <div className={style.box}>
            {isLoading ? (
              <div className={style.loader}>
                <Loader />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>

      {!!filters && (
        <Modal
          className={style.modal}
          title='Filtry'
          visible={filtersVisible}
          onClose={closeFiltersModal}
          closeOnOutsideClick
        >
          <div className={style.filters}>{filters}</div>
        </Modal>
      )}
    </>
  );
};

export default SearchWithFilters;
