import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Button, Card, PageHeader, ProcessStepper, SeoHelmet } from 'components';

import { ReactComponent as HappyTooth } from 'src/assets/icons/cheering_tooth.svg';
import { orderStatuses } from 'src/constants/enums';
import { orderProcessSteps } from 'src/constants/steps';
import pages from 'src/dictionaries/pages.json';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrderThanksPage.module.scss';

const OrderThanksPage = () => {
  const location = useLocation();
  const orderUid = location.state?.UID || location.state?.uid;
  const orderStatus = location.state?.status;
  const orderRefSavings = location.state?.ref_savings?.ref_savings;
  const orderName = location.state?.name;

  if (orderStatus === orderStatuses.draft) {
    return <Redirect to={`/orders${orderUid ? `/${orderUid}` : ''}`} />;
  }

  return (
    <>
      <SeoHelmet title={pages.orderThanksPage.title} />
      <div className={style.container}>
        <PageHeader name={orderName} />
        <ProcessStepper
          steps={orderProcessSteps}
          current={3}
        />
        <Card className={style.card}>
          <HappyTooth className={style.icon} />
          <h2>Gratulacje!</h2>
          <p className={style.text}>Twoje zamówienie zostało złożone.</p>
          {!!orderRefSavings && orderRefSavings > 0 && (
            <p className={style.savings}>
              Zaoszczędzone środki: <span className={style.color}>{getFormattedAmount(orderRefSavings)}</span>
            </p>
          )}
          <Button
            label={`Przejdź do ${orderUid ? 'zamówienia' : 'listy zamówień'}`}
            to={`/orders${orderUid ? `/${orderUid}` : ''}`}
          />
        </Card>
      </div>
    </>
  );
};

export default OrderThanksPage;
