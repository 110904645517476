import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ExtendDateForm,
  Modal,
  MoveElementForm,
  OrderOrValuationId,
  RelativeTime,
  SuppliersList,
  Tag,
  Thumbnail,
  useIsMobile,
  useModalConfirm,
  useUnreadNotifications
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi, ValuationsApi } from 'src/api';
import { valuationsNtfTypes } from 'src/constants/notifications/types';
import { tabletWidth } from 'src/constants/responsive';
import { refreshValuations } from 'src/features/ValuationView/actions';
import { toDateNumber, toTime } from 'src/utils/dateTime';
import { getDatePlusTime, getUserValuationStatusTag } from 'src/utils/helpers';

import style from './ValuationThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const ValuationThumbnail = ({ item: valuation }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(tabletWidth);

  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const unreadValuationsNtfs = useUnreadNotifications(valuationsNtfTypes);

  const handleCancel = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz anulować wycenę "${valuation.name}"?`,
      handleConfirm: cancelValuation,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const cancelValuation = async () => {
    try {
      await ValuationsApi.cancelValuation(valuation.uid);
      dispatch(refreshValuations());
      notifyCommon([`Wycena ${valuation.name} została anulowana.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleValuationExtend = async (date) => {
    try {
      await AdminApi.extendUserValuationDate(valuation.uid, date);
      notifyCommon([`Wycena została przedłużona do ${toDateNumber(date)}, ${toTime(date)}`]);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const extendValuationExpireDate = (days) => {
    handleValuationExtend(getDatePlusTime(valuation.expire_date, days));
  };

  const menu = [
    {
      title: 'Przedłuż wycenę o 24h',
      icon: 'edit',
      roles: ['admin'],
      action: () => extendValuationExpireDate(1)
    },
    {
      title: 'Przedłuż wycenę o 48h',
      icon: 'edit',
      roles: ['admin'],
      action: () => extendValuationExpireDate(2)
    },
    {
      title: 'Wybierz datę wygaśnięcia',
      icon: 'edit',
      roles: ['admin'],
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Przenieś wycenę',
      icon: 'move',
      color: 'blue',
      roles: ['admin'],
      action: () => setIsMoveModalOpen(true)
    },
    {
      title: 'Anuluj',
      icon: 'trash',
      color: 'red',
      action: handleCancel
    }
  ];

  const expireDate = !!valuation?.expire_date && (
    <p>
      Wygasa: {toDateNumber(valuation.expire_date)}, {toTime(valuation.expire_date)}
      <span className={style.counter}>
        (<RelativeTime date={valuation.expire_date} />)
      </span>
    </p>
  );

  return (
    <Thumbnail
      item={valuation}
      notificationCount={unreadValuationsNtfs?.filter((ntf) => ntf.uid === valuation?.uid).length}
      menu={menu}
      small={isMobile}
      link={`/valuations/${valuation.uid}`}
      namePrefix={'Wycena z listy: '}
      showTime
      extraInfo={
        <>
          <OrderOrValuationId
            id={valuation?.public_id}
            withPrefix={'Numer:'}
          />
        </>
      }
      status={
        <div className={shared.thumbnailContentWrapper}>
          {getUserValuationStatusTag({ status: valuation.status })}
          {valuation?.is_horizontal && (
            <Tag
              value={'Wycena horyzontalna'}
              className={shared.thumbnailTag}
              color={'blue'}
            />
          )}
          {!!valuation?.was_ordered && (
            <Tag
              value={'Złożono zamówienie'}
              className={shared.thumbnailTag}
            />
          )}
        </div>
      }
    >
      <div className={style.container}>
        {expireDate}
        <SuppliersList
          uid={valuation.uid}
          suppliers={valuation.suppliers}
          getStatusHandler={getUserValuationStatusTag}
        />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Wycena z listy: ${valuation.name}`}
      >
        <ExtendDateForm
          handleValuationExtend={handleValuationExtend}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      <Modal
        visible={isMoveModalOpen}
        onClose={() => setIsMoveModalOpen(false)}
        title={`Przenieś wycenę: ${valuation.name}`}
      >
        <MoveElementForm
          uid={valuation.uid}
          closeModal={() => setIsMoveModalOpen(false)}
          apiCallback={AdminApi.moveValuation}
          refreshCallback={() => dispatch(refreshValuations())}
          buttonLabel={'Przenieś wycenę'}
        />
      </Modal>
      {renderedModalConfirm}
    </Thumbnail>
  );
};

export default ValuationThumbnail;
