import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingIndicator, QtySelector, useAutoSave, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import style from './ProductDepartmentQuantity.module.scss';

const ProductDepartmentQuantity = ({
  id,
  uid,
  productId,
  departmentId,
  qty,
  setQty,
  unitName,
  disabled = false,
  onQuantityChange
}) => {
  const validator = useValidator();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(qty);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);

  const saveQuantityHandler = async () => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    if (!departmentId || !id) {
      setQty(quantity);
      return;
    }

    const data = {
      list_uid: uid,
      product_id: productId,
      department_id: departmentId,
      products_lists_departments_id: id,
      quantity
    };

    try {
      setIsSaveError(false);
      setIsLoading(true);
      await ListsApi.setProductDepartment(data);
      if (onQuantityChange) onQuantityChange();
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      setQuantity(qty);
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: +qty,
    second: +quantity,
    dependency: [quantity],
    saveHandler: saveQuantityHandler,
    timeoutValue: 800
  });

  return (
    <div className={style.wrapper}>
      <QtySelector
        state={quantity}
        setState={setQuantity}
        suffix={unitName}
        width={110}
        id={`offerDepartmentQty-${parent.id}`}
        rule={'required|only_positive'}
        validator={validator}
        disabled={disabled}
        horizontalMobile
      />
      <LoadingIndicator
        isLoading={isLoading}
        hasError={isSaveError}
        aboveInput
      />
    </div>
  );
};

export default ProductDepartmentQuantity;
