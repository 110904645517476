import { FilterByDateRange, Searchbar, useIsMobile } from 'components';

import { tabletHorizontalWidth } from 'src/constants/responsive';

const Filtering = ({ params }) => {
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);

  const tabletHorizontalContent = (
    <Searchbar
      params={params}
      filters={
        <FilterByDateRange
          params={params}
          isExpenses
        />
      }
    />
  );

  const desktopContent = (
    <>
      <div>
        <Searchbar params={params} />
      </div>
      <FilterByDateRange
        params={params}
        isExpenses
      />
    </>
  );

  return isTabletHorizontal ? tabletHorizontalContent : desktopContent;
};

export default Filtering;
