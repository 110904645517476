import * as types from './types';

export const toggleSidebar = () => ({
  type: types.TOGGLE_SIDEBAR
});

export const closeSidebar = () => ({
  type: types.CLOSE_SIDEBAR
});

export const openMobileSidebar = () => ({
  type: types.OPEN_MOBILE_SIDEBAR
});

export const closeMobileSidebar = () => ({
  type: types.CLOSE_MOBILE_SIDEBAR
});
