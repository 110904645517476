import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Modal } from 'components';

import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

import SuppliersList from '../SuppliersList';

import style from './ProductButtons.module.scss';

const ProductButtons = (props) => {
  const { product, changeValuationSelections, selectedCompany, isQuietLoading } = props;

  const [visible, setVisible] = useState(false);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);

  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  const modal = (
    <Modal
      visible={visible}
      onClose={handleClose}
      title={'Edycja oferty'}
    >
      <SuppliersList
        product={product}
        changeValuationSelections={changeValuationSelections}
        selectedCompany={selectedCompany}
        isOpen={visible}
        setIsOpen={setVisible}
        isQuietLoading={isQuietLoading}
      />
    </Modal>
  );

  return (
    <>
      <button
        className={cn(style.button, { [style.fullView]: !isDefinedView })}
        onClick={handleOpen}
        title={'Edycja oferty'}
      >
        <div className={style.container}>
          <EditIcon className={style.icon} />
        </div>
      </button>
      {modal}
    </>
  );
};

export default ProductButtons;
