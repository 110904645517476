import React from 'react';
import cn from 'classnames';
import classNames from 'classnames';

import { Menu } from 'components';
import BlurDataInfo from 'components/layout/BlurDataInfo';
import { titlePlaceholder } from 'components/layout/PageHeader/placeholders';

import { ReactComponent as Calendar } from 'src/assets/icons/calendar-check.svg';
import { ReactComponent as Cart } from 'src/assets/icons/cart.svg';
import { ReactComponent as User } from 'src/assets/icons/circle-user.svg';
import { ReactComponent as Info } from 'src/assets/icons/info-icon.svg';
import { toDate, toTime } from 'src/utils/dateTime';

import style from './PageHeader.module.scss';
import shared from 'styles/Shared.module.scss';

const renderCreatedDate = (created_at) => {
  if (!created_at) return null;

  return (
    <p
      className={style.text}
      key={created_at}
    >
      <Calendar className={style.icon} /> {toDate(created_at)}, {toTime(created_at)}
    </p>
  );
};

const renderAuthor = (author) => {
  if (!author) return null;

  return (
    <p
      className={style.text}
      key={author}
    >
      <User className={style.icon} /> {author}
    </p>
  );
};

const renderDefault = (value) => {
  if (!value) return null;

  return (
    <p
      className={style.text}
      key={value}
    >
      <Info className={style.icon} /> {value}
    </p>
  );
};

const renderOrderId = (orderId) => {
  if (!orderId) return null;

  return (
    <p
      className={style.text}
      key={orderId}
    >
      <Cart className={style.icon} /> #{orderId}
    </p>
  );
};

const metaDataRenderers = {
  created_at: renderCreatedDate,
  author: renderAuthor,
  order_id: renderOrderId,
  default: renderDefault
};

const PageHeader = ({
  name,
  text,
  actions,
  children,
  className,
  isLoading,
  hideChildrenWhenLoading,
  textCenter,
  valuations,
  wrapOnMobile,
  titleClasses,
  blurTitle,
  blurredTitlePrefix,
  textMaxWidth,
  metaData,
  breakpointBelowTablet = false
}) => {
  const renderMetaData = () => {
    let items = [];
    for (let key in metaData) {
      const renderer = metaDataRenderers[key] || metaDataRenderers.default;
      items.push(renderer(metaData[key]));
    }

    const divider = <span className={style.divider} />;

    return items.reduce((acc, item, index) => {
      acc.push(item);
      if (index < items.length - 1) {
        acc.push(React.cloneElement(divider, { key: `divider-${index}` }));
      }
      return acc;
    }, []);
  };

  return (
    <header
      className={cn(
        shared.pageHeader,
        { [style.withMeta]: !!metaData, [style.wrapBelowTablet]: breakpointBelowTablet },
        className
      )}
    >
      <div
        className={classNames(shared.titleWrapper, titleClasses, {
          [shared.center]: textCenter,
          [shared.wrapOnMobile]: wrapOnMobile,
          [shared.breakpointBelowTablet]: breakpointBelowTablet
        })}
      >
        {isLoading ? (
          titlePlaceholder()
        ) : (
          <div className={style.textData}>
            {name && (
              <>
                {!!metaData && <div className={style.metaData}>{renderMetaData()}</div>}
                {!!blurredTitlePrefix && blurTitle && <h2 className={classNames(shared.pageTitle)}>{blurredTitlePrefix}</h2>}
                <h2 className={classNames(shared.pageTitle, { [shared.blur]: blurTitle })}>{name}</h2>
              </>
            )}
            {blurTitle && <BlurDataInfo />}
            {text && (
              <h3
                className={cn(shared.pageSubtitle, { [shared.maxWidth]: textMaxWidth })}
                title={text}
              >
                {text}
              </h3>
            )}
          </div>
        )}
      </div>
      {!!children && (
        <div
          className={classNames(shared.headerWrapper, {
            [shared.valuationsHeader]: valuations,
            [shared.breakpointBelowTablet]: breakpointBelowTablet
          })}
        >
          {hideChildrenWhenLoading && isLoading ? titlePlaceholder('400px') : children}
        </div>
      )}
      {!!actions && (
        <Menu
          actions={actions}
          className={classNames(shared.pageHeaderMenu, {
            [shared.breakpointBelowTablet]: breakpointBelowTablet
          })}
        />
      )}
    </header>
  );
};

export default PageHeader;
