import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import { useIsMobile } from 'components';
import GoBackButton from 'components/layout/GoBackButton';
import ScrollToTopButton from 'components/layout/Page/components/ScrollToTopButton';

import { tabletHorizontalWidth } from '../../../constants/responsive';
import Header from '../Header';
import Sidebar from '../Sidebar';

import styles from './Page.module.scss';

const Page = ({ wide, children, title, showGoBackButton, goBackButtonUrl, showToTopButton }) => {
  const sidebarStore = useSelector((state) => state.sidebar);
  const isAdminView = useSelector((state) => state.auth.isAdminView);
  const { isOpen, isMobileSidebarOpen } = sidebarStore;
  const isMobile = useIsMobile();
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);
  const pageContainer = useRef(null);

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = isMobileSidebarOpen ? 'hidden' : 'auto';
    } else {
      document.body.style.overflow = isOpen && isTabletHorizontal ? 'hidden' : 'auto';
    }

    return () => (document.body.style.overflow = 'auto');
  }, [isMobileSidebarOpen, isTabletHorizontal, isOpen]);

  return (
    <>
      {isMobile && <Sidebar />}
      <div
        ref={pageContainer}
        className={cn(styles.page, {
          [styles.closedSidebar]: !isOpen,
          [styles.isMobile]: isMobile,
          [styles.isMobileOpen]: isMobileSidebarOpen && isMobile,
          [styles.isMobileClose]: !isMobileSidebarOpen && isMobile,
          [styles.isTablet]: isTabletHorizontal && !isMobile,
          [styles.isTabletOpen]: isOpen && isTabletHorizontal && !isMobile,
          [styles.isTabletClose]: !isOpen && isTabletHorizontal && !isMobile,
          [styles.isAdminView]: isAdminView
        })}
      >
        {!isMobile && <Sidebar className={styles.sidebar} />}
        <Header />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: true
            })}
          >
            {showGoBackButton && (
              <GoBackButton
                wide={wide}
                goBackUrl={goBackButtonUrl}
              />
            )}
            {title && <div className={cn('h3', styles.title)}>{title}</div>}
            {children}
            {showToTopButton && <ScrollToTopButton container={pageContainer.current} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Page);
