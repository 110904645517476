import * as types from '../actions/types';

const getSidebarInfo = () => {
  if (localStorage.getItem('isSidebarOpen')) {
    return JSON.parse(localStorage.getItem('isSidebarOpen'));
  } else {
    localStorage.setItem('isSidebarOpen', JSON.stringify(true));
    return true;
  }
};

const defaultState = {
  isOpen: getSidebarInfo(),
  isMobileSidebarOpen: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      localStorage.setItem('isSidebarOpen', JSON.stringify(!state.isOpen));
      return {
        ...state,
        isOpen: !state.isOpen
      };
    case types.CLOSE_SIDEBAR:
      localStorage.setItem('isSidebarOpen', JSON.stringify(false));
      return {
        ...state,
        isOpen: false
      };
    case types.OPEN_MOBILE_SIDEBAR:
      return {
        ...state,
        isMobileSidebarOpen: true
      };
    case types.CLOSE_MOBILE_SIDEBAR:
      return {
        ...state,
        isMobileSidebarOpen: false
      };
    default:
      return state;
  }
};
