import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingIndicator, QtySelector, useAutoSave, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView } from '../../actions';

import style from './SetQuantity.module.scss';

const SetQuantity = ({ uid, id, combinedQuantity, unitName, disabled = false }) => {
  const validator = useValidator();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(combinedQuantity);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);

  const saveQuantityHandler = async () => {
    const data = {
      product_id: id,
      dont_sum: true,
      quantity: quantity || 0,
      uid
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsSaveError(false);
      setIsLoading(true);
      await ListsApi.storeToList(data);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      setQuantity(combinedQuantity);
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: +combinedQuantity,
    second: +quantity,
    dependency: [quantity],
    saveHandler: saveQuantityHandler,
    timeoutValue: 800
  });

  return (
    <div className={style.wrapper}>
      <QtySelector
        state={disabled ? combinedQuantity : quantity}
        setState={setQuantity}
        suffix={unitName}
        width={110}
        id={`offerQty-${parent.id}`}
        rule={'only_positive_or_zero'}
        validator={validator}
        disabled={disabled}
        horizontalMobile
      />
      <LoadingIndicator
        isLoading={isLoading}
        hasError={isSaveError}
        aboveInput
      />
    </div>
  );
};

export default SetQuantity;
