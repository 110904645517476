import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Popover } from 'antd';
import classNames from 'classnames';

import {
  Checkbox,
  CommentModal,
  CustomTooltip,
  InfoWidget,
  Menu,
  Tag,
  useDepartments,
  useIsMobile,
  VisibilityCallback
} from 'components';
import useReadNotification from 'components/hooks/useReadNotification';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import ProductStatusExtended from 'components/layout/ProductStatusExtended';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { ReactComponent as WarehouseIcon } from 'src/assets/icons/newSidebar/warehouse-check.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/up-arrow.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import replacementThumbnail from 'src/assets/img/replacement-thumbnail.png';
import { NotificationTypes, orderProductTypes, orderStatuses } from 'src/constants/enums';
import { tabletVerticalWidth } from 'src/constants/responsive';
import { triggerDepartmentDependency } from 'src/features/CartOrder/actions';
import { getTimeAgo, toDateNumber, toTime } from 'src/utils/dateTime';
import { getFormattedAmount, getProductStatusTag } from 'src/utils/helpers';

import ProductDepartmentThumbnail from '../ProductDepartmentThumbnail';

import style from './OrderProductThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const OrderProductThumbnail = (props) => {
  const {
    product,
    isSelected,
    onSelectChange,
    type,
    hideStatusColumn,
    showDepartments,
    onQtyChange,
    addedToWarehouse,
    qtyWithoutMargin,
    index,
    priceChangeData,
    onPriceChange,
    notifications,
    supplierId,
    onDepartmentChange,
    showTags = false,
    uid = undefined,
    tabletVerticalBreakpoint = false,
    cardView = false
  } = props;

  const {
    name,
    producer,
    unit_name,
    qty,
    price,
    price_for_one,
    comment,
    thumbnail,
    image,
    status,
    type: productType,
    product_id,
    received_quantity,
    tags = []
  } = product || {};
  const dispatch = useDispatch();

  const { prevPrice, date } = priceChangeData || {};
  const priceWrapperRef = useRef(false);
  const productNotification = useMemo(
    () => notifications?.find((ntf) => ntf.data?.product_id === product_id),
    [notifications, product]
  );

  const showStatusBadge = useMemo(() => {
    const orderNtf = notifications?.find(
      (ntf) =>
        (ntf?.data?.supplier_id === supplierId &&
          [NotificationTypes.OrderAccepted, NotificationTypes.OrderCancelledBySupplier].includes(ntf.type)) ||
        []
    );
    return (
      orderNtf?.data?.cancelledProducts?.find((id) => id === product_id) ||
      orderNtf?.type === NotificationTypes.OrderCancelledBySupplier
    );
  }, [notifications, supplierId]);

  const isMobile = useIsMobile(hideStatusColumn ? 767 : 991);
  const isTabletVertical = useIsMobile(tabletVerticalWidth);

  const [isQtyActive, setIsQtyActive] = useState(false);
  const [isPriceActive, setIsPriceActive] = useState(false);
  const [readNotificationHandler] = useReadNotification(productNotification);
  const [departments, setDepartments] = useState(product.departments || []);

  const myDepartments = useDepartments();
  const hasDepartments = departments.length > 0;

  const newQuantityProducts = useSelector((state) => state.valuationView.newQuantityProducts);
  const newQuantity = useMemo(() => {
    return newQuantityProducts.find((item) => item.id === product.id)?.new_quantity;
  }, [newQuantityProducts]);

  const wasQtyChange = useMemo(() => newQuantity && +newQuantity !== qty, [newQuantity, qty]);

  const findUnusedDepartments = () => {
    return myDepartments.departments.filter((item) => !departments.map((i) => i.id).includes(item.id)) || [];
  };

  const addProductDepartment = async () => {
    const unused = findUnusedDepartments();
    if (unused.length === 0) return;

    setDepartments([
      ...departments,
      {
        id: undefined,
        name: undefined,
        quantity: undefined,
        products_lists_departments_id: undefined,
        created_at: undefined
      }
    ]);

    dispatch(triggerDepartmentDependency());
  };

  const onDepartmentDelete = (index) => {
    const isNew = !departments[index].products_lists_departments_id;
    if (isNew) {
      setDepartments(departments.filter((_, i) => i !== index));
      dispatch(triggerDepartmentDependency());
    } else {
      if (onDepartmentChange) onDepartmentChange();
    }
  };

  useEffect(() => {
    setDepartments(product.departments || []);
  }, [product.departments]);

  const popoverContent = useMemo(() => {
    if (!showTags) return null;
    if (!tags) return null;

    return (
      <div className={shared.popoverWrapper}>
        {tags.map((tag) => (
          <Tag
            key={`ProductDetailsTag-${tag.id}`}
            value={tag.name}
            color={tag.color}
          />
        ))}
      </div>
    );
  }, [tags, showTags]);

  const actions = useMemo(() => {
    const unusedDepartments = findUnusedDepartments();
    const canSetDepartment = showDepartments && !!uid && unusedDepartments.length > 0;

    const menu = [
      {
        title: 'Zmień ilość w zamówieniu',
        icon: 'edit',
        action: () => setTimeout(() => setIsQtyActive(true), 50),
        disabled: showDepartments && hasDepartments
      },
      canSetDepartment
        ? {
            title: 'Przydziel jednostce',
            icon: 'move',
            action: addProductDepartment
          }
        : undefined
    ].filter((i) => i !== undefined);

    return menu;
  }, [myDepartments, departments]);

  const warehouseInfo = (
    <CustomTooltip title={'Dodano do magazynu'}>
      <WarehouseIcon className={style.tick} />
    </CustomTooltip>
  );

  const priceTooltipContent =
    +prevPrice > 0 ? (
      <div className={style.tooltip}>
        <p>Poprzednia cena: {getFormattedAmount(prevPrice)}</p>
        {!!date && (
          <p>
            Zmieniono {getTimeAgo(date).date}
            <br />({toDateNumber(date)}, {toTime(date)})
          </p>
        )}
      </div>
    ) : null;

  const priceChangeIndicator =
    +prevPrice && +prevPrice !== +price_for_one ? (
      <ArrowIcon className={+price_for_one > +prevPrice ? style.up : style.down} />
    ) : null;

  return (
    <div
      className={classNames(style.container, {
        [style.cancelled]: status === 'CANCELLED',
        [style.replacement]: productType === 'replacement',
        [style.offer]: productType === 'offer'
      })}
    >
      <div
        id={productNotification ? `suborder-product-${productNotification.data?.product_id}` : undefined}
        className={classNames(style.productItem, {
          [style.hideStatusColumn]: hideStatusColumn,
          [style.showTags]: showTags,
          [style.cardView]: cardView || (tabletVerticalBreakpoint && isTabletVertical)
        })}
      >
        {!isTabletVertical && !cardView && <p>{index}.</p>}
        {((tabletVerticalBreakpoint && isTabletVertical) || cardView) && (
          <ProductThumbnailImage
            className={style.image}
            thumbnail={type === 'replacement' ? replacementThumbnail : thumbnail}
            image={type === 'replacement' ? null : image}
            title={name}
            id={product_id}
          />
        )}
        <div
          className={classNames(style.wrapper, style.productName, {
            [style.hideStatusColumn]: hideStatusColumn,
            [style.withImageGrid]: (isTabletVertical && tabletVerticalBreakpoint) || cardView
          })}
        >
          {((!tabletVerticalBreakpoint && !cardView) || (tabletVerticalBreakpoint && !isTabletVertical)) && (
            <ProductThumbnailImage
              thumbnail={type === 'replacement' ? replacementThumbnail : thumbnail}
              image={type === 'replacement' ? null : image}
              title={name}
              id={product_id}
            />
          )}
          <p className={classNames(style.text, style.trim)}>
            {type === 'replacement' && (
              <span className={classNames(style.info, style.replacement)}>
                <WarningIcon />
                Zamiennik
              </span>
            )}
            {type === 'offer' && <span className={classNames(style.info, style.offer)}>Oferta dostawcy</span>}
            {name}
          </p>
        </div>
        <div className={style.data}>
          <div className={classNames(style.wrapper, style.producer)}>
            {(isMobile || cardView) && <p className={style.label}>Producent: </p>}
            <p className={style.text}>{producer}</p>
          </div>
          <div className={classNames(style.wrapper, style.qty)}>
            {(isMobile || cardView) && <p className={style.label}>Ilość: </p>}
            {onQtyChange ? (
              <ChangeQuantity
                wasQtyChange={wasQtyChange}
                quantity={newQuantity || qty}
                unit={unit_name}
                setQuantity={onQtyChange}
                isActive={isQtyActive}
                setIsActive={setIsQtyActive}
                withoutMargin={qtyWithoutMargin}
                displayOver
                disabled={showDepartments && hasDepartments}
              />
            ) : (
              <p className={style.text}>{`${newQuantity || qty} ${unit_name ? unit_name : ''}`}</p>
            )}
            {received_quantity > 0 && received_quantity < qty && (
              <InfoWidget
                place={'bottom'}
                className={style.infoQty}
              >
                <p>
                  Odebrano {received_quantity} / {qty} {unit_name ? unit_name : ''}
                </p>
              </InfoWidget>
            )}
          </div>
          <div
            className={style.wrapper}
            ref={priceWrapperRef}
          >
            {(isMobile || cardView) && <p className={style.label}>Cena za szt.: </p>}
            {onPriceChange ? (
              <>
                <ChangePrice
                  price={price_for_one}
                  setPrice={onPriceChange}
                  isActive={isPriceActive}
                  setIsActive={setIsPriceActive}
                  displayOver
                />
                <CustomTooltip title={priceTooltipContent}>{priceChangeIndicator}</CustomTooltip>
              </>
            ) : (
              <CustomTooltip title={priceTooltipContent}>
                <p className={style.text}>
                  {getFormattedAmount(price_for_one)}
                  {priceChangeIndicator}
                </p>
              </CustomTooltip>
            )}
            <VisibilityCallback
              notification={productNotification}
              elementRef={priceWrapperRef}
              callback={readNotificationHandler}
            />
          </div>
          <div className={style.wrapper}>
            {(isMobile || cardView) && <p className={style.label}>Wartość: </p>}
            <p className={style.text}>{getFormattedAmount(price)}</p>
          </div>
          {showTags && (
            <div className={classNames(style.wrapper, style.tags)}>
              {!tags || (tags?.length === 0 && <p className={style.text}>-</p>)}
              {tags?.length > 0 && (
                <Popover
                  key={`ProductDetailsTagPopover-${product_id}`}
                  content={popoverContent}
                  placement={'bottomRight'}
                >
                  <Tag
                    value={tags[0].name}
                    color={tags[0].color}
                    className={style.tag}
                  />
                </Popover>
              )}
            </div>
          )}
        </div>
        <div className={style.additionalFields}>
          <div className={classNames(style.wrapper, style.comment)}>
            {(isMobile || cardView) && <p className={style.label}>Komentarz do produktu: </p>}
            <CommentModal
              comment={comment}
              modalTitle={'Komentarz do produktu'}
              title={name}
              className={style.commentModal}
            />
          </div>
          {!hideStatusColumn && (
            <div className={style.checkboxWrapper}>
              <ProductStatusExtended
                product={product}
                className={style.status}
              />
              {!!addedToWarehouse && warehouseInfo}
              <Badge
                dot={!!showStatusBadge}
                offset={[-10, -10]}
              />
              {type === orderProductTypes.supplier && status === orderStatuses.pending && (
                <Checkbox
                  value={isSelected}
                  onChange={onSelectChange}
                  wrapperStyle={style.checkbox}
                />
              )}
            </div>
          )}
        </div>
        {!!onQtyChange && (
          <Menu
            actions={actions}
            className={style.menu}
            displayOver
          />
        )}
      </div>

      {showDepartments && departments.length > 0 && (
        <div className={style.departments}>
          {departments.map((department, i) => (
            <ProductDepartmentThumbnail
              key={`ProductDepartmentThumbnail-${department.products_lists_departments_id || `idx-${i}`}`}
              className={style.departmentItem}
              uid={uid}
              product={product}
              productId={product.product_id}
              department={department}
              onChange={onDepartmentChange}
              onDelete={() => onDepartmentDelete(i)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderProductThumbnail;
