import React from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

const UpdateCheck = () => {
  const { updateServiceWorker } = useRegisterSW({
    onNeedRefresh() {
      updateServiceWorker(true);
    }
  });

  return null;
};

export default UpdateCheck;
