import React from 'react';
import classNames from 'classnames';

import { Icon } from 'components';

import style from './FilterValueItem.module.scss';

const FilterValueItem = ({ value, icon, color, onDelete }) => {
  return (
    <div
      className={style.item}
      title={value}
    >
      <div className={style.iconWrapper}>
        <Icon name={icon || 'filter'} />
      </div>
      {!!color && (
        <div
          className={style.colorIndicator}
          style={{ backgroundColor: color }}
        ></div>
      )}
      <span>{value}</span>
      <button
        className={classNames(style.delete)}
        onClick={onDelete}
      >
        <Icon
          name='close'
          fill={'#515c6a'}
          size={14}
        />
      </button>
    </div>
  );
};

export default FilterValueItem;
