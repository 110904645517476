import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  FilterByCompany,
  Modal,
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  useIsAdmin,
  useIsMobile
} from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import addIcon from 'src/assets/icons/add.svg';
import { tabletHorizontalWidth } from 'src/constants/responsive';
import pages from 'src/dictionaries/pages.json';
import SupplierForm from 'src/features/Suppliers/components/SupplierForm';
import SuppliersTable from 'src/features/Suppliers/components/SuppliersTable';

import { getCorrectFormOfResultsLabel } from '../../utils/helpers';
import query from '../../utils/query';

import style from './Suppliers.module.scss';

const Suppliers = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);
  const isAdmin = useIsAdmin();

  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [querySearch, setQuerySearch] = useState();
  const [queryCompany, setQueryCompany] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [perPage, setPerPage] = useState(50);
  const [pageQty, setPageQty] = useState(1);
  const [itemsQty, setItemsQty] = useState();
  const version = useSelector((state) => state.suppliers.version);

  const getData = async () => {
    const queryParams = {
      pagination: 1,
      page: currentPage || 1,
      search: querySearch,
      company_id: queryCompany,
      perPage
    };

    try {
      const { data } = await UserApi.getDistributors(queryParams);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    if (version > 0) {
      getData();
    }
  }, [version]);

  useEffect(() => {
    const getInitialDate = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getInitialDate();
  }, [querySearch, currentPage, queryCompany, perPage]);

  useEffect(() => {
    setQuerySearch(params.get('search'));
    setQueryCompany(params.get('company'));
    setCurrentPage(params.get('page'));
  }, [params]);

  const addSupplierButton = (
    <Button
      label='Dodaj dostawcę'
      onClick={() => setIsModalOpen(true)}
      icon={addIcon}
    />
  );

  return (
    <>
      <SeoHelmet title={pages.suppliers.title} />
      <PageHeader
        name={pages.suppliers.title}
        wrapOnMobile
        breakpointBelowTablet
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        {!isTabletHorizontal && (
          <Searchbar
            params={params}
            wrapperStyle={style.search}
          />
        )}
        <FilterByCompany
          params={params}
          fullWidth={isMobile}
        />
        {!isAdmin && addSupplierButton}
      </PageHeader>
      <div className={style.container}>
        {isTabletHorizontal && (
          <Searchbar
            params={params}
            wrapperStyle={style.search}
            fullWidth
          />
        )}
        <SuppliersTable
          list={pageData}
          isLoading={isLoading}
        />
        <Modal
          visible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Dodaj dostawcę'}
        >
          <SupplierForm closeModal={() => setIsModalOpen(false)} />
        </Modal>
      </div>
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        defaultQty={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
};

export default Suppliers;
