import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { LoadingIndicator, SaveButton, Select, useAutoSave, useDepartments, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import ProductDepartmentQuantity from './components/ProductDepartmentQuantity';

import style from './ProductDepartmentThumbnail.module.scss';

const departmentToOption = (department) => {
  if (!department.id) return null;
  const { id, name } = department;
  return { label: name, value: id };
};

const ProductDepartmentThumbnail = ({ uid, product, productId, department, onChange, onDelete, className }) => {
  const { id, products_lists_departments_id, name, quantity } = department;

  const myDepartments = useDepartments();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [departmentValue, setDepartmentValue] = useState(departmentToOption(department) || null);
  const [initialDepartmentQty, setInitialDepartmentQty] = useState(
    quantity || product.departments.length === 0 ? product?.qty || 1 : 1
  );

  const options = useMemo(() => {
    const unused = myDepartments.departments.filter((item) => !product.departments.map((i) => i.id).includes(item.id));
    const asOptions = unused.map((item) => departmentToOption(item));
    return asOptions;
  }, [myDepartments, product.departments]);

  const deleteProductDepartmentHandler = async () => {
    if (!products_lists_departments_id) {
      onDelete();
      return;
    }

    try {
      await ListsApi.deleteProductDepartment(uid, products_lists_departments_id);
      notifyCommon(['Jednostka organizacyjna została usunięta dla tego produktu.']);
      if (onDelete) onDelete();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const changeDepartmentHandler = async () => {
    if (!departmentValue) return;
    try {
      setIsSaveError(false);
      setIsLoading(true);

      const data = {
        list_uid: uid,
        product_id: productId,
        department_id: departmentValue.value,
        quantity: quantity || initialDepartmentQty,
        products_lists_departments_id
      };

      await ListsApi.setProductDepartment(data);
      if (onChange) onChange();
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      setDepartmentValue(departmentToOption(department));
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: +department?.id,
    second: +departmentValue?.value,
    dependency: [departmentValue],
    saveHandler: changeDepartmentHandler,
    timeoutValue: 800
  });

  return (
    <div className={classNames(style.container, className)}>
      <div className={style.wrapper}>
        <Select
          options={options}
          value={departmentValue}
          onChange={setDepartmentValue}
          placeholder={'Wybierz jedn. organizacyjną'}
          isSearchable={false}
        />
        <LoadingIndicator
          isLoading={isLoading}
          hasError={isSaveError}
          aboveInput
        />
      </div>
      <div className={style.spacing}></div>
      <div className={style.actions}>
        <div className={style.wrapper}>
          <span className={style.label}>Ilość:</span>
          <ProductDepartmentQuantity
            id={products_lists_departments_id}
            uid={uid}
            productId={productId}
            departmentId={department.id}
            qty={quantity || initialDepartmentQty}
            setQty={setInitialDepartmentQty}
            onQuantityChange={onChange}
            unitName={product.unit_name}
          />
        </div>
        <SaveButton
          type='delete'
          onClick={() =>
            handleOpenModalConfirm({
              message: `Czy na pewno chcesz usunąć ${name || 'nową jedn. organizacyjną'} z podziału produktu ${product.name}?`,
              handleConfirm: deleteProductDepartmentHandler
            })
          }
        />
      </div>
      {renderedModalConfirm}
    </div>
  );
};

export default ProductDepartmentThumbnail;
