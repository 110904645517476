import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, Loader, useIsMobile } from 'components';

import { imageErrorHandler } from 'src/utils/helpers';

import style from './Button.module.scss';

const ButtonWrapper = (props) => {
  const {
    children,
    to,
    onClick,
    className,
    height,
    width,
    type,
    isLoading,
    disabled = false,
    gray = false,
    white = false,
    empty = false,
    cancel = false,
    form,
    id,
    title,
    icon,
    color,
    state,
    href
  } = props;

  const isMobile = useIsMobile();

  const buttonClasses = classNames(style.button, className, {
    [style.disabled]: disabled,
    [style.isLoading]: isLoading,
    [style.empty]: empty,
    [style.cancel]: cancel,
    [style.gray]: gray,
    [style.white]: white,
    [style.withIcon]: Boolean(icon),
    [style.mobile]: isMobile,
    [style[color]]: color
  });

  const handleOnClick = (e) => {
    if (onClick && (!disabled || isLoading)) {
      onClick(e);
    }
  };

  return to ? (
    <Link
      className={buttonClasses}
      style={{ height, width }}
      onClick={handleOnClick}
      to={{ pathname: to, state }}
    >
      {children}
    </Link>
  ) : href ? (
    <a
      className={buttonClasses}
      style={{ height, width }}
      href={href}
    >
      {children}
    </a>
  ) : (
    <button
      onClick={handleOnClick}
      className={buttonClasses}
      style={{ height, width }}
      type={type}
      disabled={disabled}
      id={id}
      form={form}
      title={title}
    >
      {children}
    </button>
  );
};

const Button = (props) => {
  const { label, isLoading = false, icon, hideLabelOnMobile, iconName } = props;
  const isMobile = useIsMobile();

  const getIcon = () => {
    if (isLoading) {
      return <Loader light={!props?.gray} />;
    } else if (icon) {
      return (
        <img
          src={icon}
          alt={'button icon'}
          className={style.icon}
          onError={imageErrorHandler}
        />
      );
    } else if (iconName) {
      return (
        <Icon
          name={iconName}
          size={17}
          fill={'#ffffff'}
        />
      );
    } else {
      return null;
    }
  };

  const content = (
    <>
      {getIcon()}
      {label && !(isMobile && hideLabelOnMobile) && <span>{label}</span>}
    </>
  );

  return <ButtonWrapper {...props}>{content}</ButtonWrapper>;
};

export default Button;
