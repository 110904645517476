import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  EmptyState,
  Modal,
  useIsAdmin,
  useIsMobile,
  useManageMany,
  useModalConfirm,
  useRecentlyOrderedProducts
} from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import ProductsSectionsPlaceholder from 'components/layout/ProductsSectionPlaceholder';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { tabletHorizontalWidth } from 'src/constants/responsive';
import { refreshListView } from 'src/features/ListView/actions';
import ListViewItem from 'src/features/ListView/components/ListViewItem';
import ProductCommentForm from 'src/features/ListView/components/ProductCommentForm';
import { handleNumeralWords } from 'src/utils/helpers';

import { FilterByDepartment } from '../../../../components';

import style from './ListViewTable.module.scss';
import shared from 'styles/Shared.module.scss';

const ListViewTable = ({ list, isLoading, uid, params }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);
  const recentlyOrdered = useRecentlyOrderedProducts(uid);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const headerClasses = classNames(shared.tableHeader, style.header);

  const products = useMemo(() => {
    const arr = [];
    list.forEach((cat) => arr.push(...cat.items));
    return arr;
  }, [list]);

  const deleteSelectedElements = async (elements, resetHandler) => {
    try {
      await ListsApi.deleteProduct(uid, elements);
      notifyCommon([
        `${handleNumeralWords(
          ['Produkt został usunięty.', 'Produkty zostały usunięte', 'Produkty zostały usunięte'],
          elements.length
        )}`
      ]);
      dispatch(refreshListView());
      resetHandler();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteSelectedElements = (elements = [], resetHandler) => {
    handleOpenModalConfirm({
      handleConfirm: () => deleteSelectedElements(elements, resetHandler),
      message: `Czy na pewno chcesz usunąć ${elements.length} ${handleNumeralWords(
        ['produkt', 'produkty', 'produktów'],
        elements.length
      )} z listy?`
    });
  };

  const {
    BulkManagementModule,
    selectedElements,
    selectAllCheckbox,
    renderSingleCheckbox,
    renderCheckboxForGroup,
    resetHandler
  } = useManageMany({
    allElements: products,
    options: [
      { label: 'Dodaj wybrane do listy', value: '0', onClick: () => setIsModalAddToListOpen(true) },
      { label: 'Dodaj komentarz do wybranych', value: '1', onClick: () => setIsCommentModalOpen(true) },
      { label: 'Usuń wybrane', value: '2', onClick: (elements) => handleDeleteSelectedElements(elements, resetHandler) }
    ],
    ...(isTabletHorizontal && {
      buttonProps: {
        color: 'gray'
      }
    })
  });

  return (
    <>
      <div className={style.filters}>
        <FilterByDepartment
          params={params}
          isMulti
        />
        {!isLoading && list.length > 0 && BulkManagementModule}
      </div>

      {isLoading && (
        <section className={shared.tableWrapper}>
          <div className={headerClasses}>
            <p>Lp.</p>
            <p>Nazwa</p>
            <p>Producent</p>
            <p>Komentarz</p>
            <p>Ilość</p>
          </div>
          <ProductsSectionsPlaceholder />
        </section>
      )}

      {!isLoading && list.length === 0 && (
        <section className={shared.tableWrapper}>
          <EmptyState type={'emptyList'} />
        </section>
      )}

      {!isLoading && list.length > 0 && (
        <>
          <section className={shared.tableWrapper}>
            <div className={headerClasses}>
              {selectAllCheckbox || <p className={style.hideOnPrintView}>Lp.</p>}
              <p>Nazwa</p>
              <p>Producent</p>
              <p>Komentarz</p>
              <p className={style.mobilePadding}>Ilość</p>
            </div>
            {list.map(
              (cat) =>
                cat.items.length > 0 && (
                  <div
                    className={shared.tableCategory}
                    key={cat.name}
                  >
                    <header className={style.catHeader}>
                      <div className={style.checkboxBox}>
                        {renderCheckboxForGroup(
                          cat.items.map((item) => item.id),
                          style.checkbox
                        )}
                      </div>
                      <h3 className={shared.tableCategoryTitle}>
                        {cat.name} <span className={shared.counter}>({cat.items?.length})</span>
                      </h3>
                    </header>
                    {cat.items.map((item) => (
                      <ListViewItem
                        key={String(item.id + item.name)}
                        item={item}
                        uid={uid}
                        index={products.map((prod) => prod.id).indexOf(item.id)}
                        recentlyOrdered={recentlyOrdered.find((prod) => prod.product_id === item.id)}
                      >
                        {renderSingleCheckbox(item.id)}
                      </ListViewItem>
                    ))}
                  </div>
                )
            )}
          </section>
          {BulkManagementModule}
          <AddProductToList
            title={`Dodaj ${selectedElements.length} ${handleNumeralWords(
              ['produkt', 'produkty', 'produktów'],
              selectedElements.length
            )} do wybranej listy`}
            selectedProducts={selectedElements}
            visible={isModalAddToListOpen}
            products={products}
            onClose={() => setIsModalAddToListOpen(false)}
            onSuccess={resetHandler}
          />
          <Modal
            visible={isCommentModalOpen}
            onClose={() => setIsCommentModalOpen(false)}
            title={`Dodaj komentarz do ${selectedElements.length} ${handleNumeralWords(
              ['produkt', 'produkty', 'produktów'],
              selectedElements.length
            )}`}
          >
            <ProductCommentForm
              closeModal={() => setIsCommentModalOpen(false)}
              productIds={selectedElements}
              uid={uid}
            />
          </Modal>
          {renderedModalConfirm}
        </>
      )}
    </>
  );
};

export default ListViewTable;
