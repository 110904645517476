import React, { useEffect } from 'react';
import ScanbotSDK from 'scanbot-web-sdk/ui';

import {
  applyActionBarConfig,
  applyPermissionScreenConfig,
  applySingleScanningUseCase,
  applyTopBarConfig,
  startScanner
} from './functions';

const Scanner = ({ onScan, closeScanner }) => {
  useEffect(() => {
    const startScanning = async () => {
      const hasDOMScannerElement = document.getElementById('barcode-scanner-controller');
      if (hasDOMScannerElement) return;

      const config = new ScanbotSDK.UI.Config.BarcodeScannerConfiguration();
      applySingleScanningUseCase(config);
      applyTopBarConfig(config);
      applyActionBarConfig(config);
      applyPermissionScreenConfig(config);

      const result = await startScanner(config);
      if (result && result.items.length > 0) {
        onScan(result.items[0].text);
      } else {
        closeScanner && closeScanner();
      }
    };

    startScanning();

    return () => {
      closeScanner && closeScanner();
    };
  }, []);

  return null;
};

export default Scanner;
